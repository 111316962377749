import { useCallback, useEffect, useState } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import awsConfig from './aws-config.json'
import './App.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { Login } from './components/Login';
import { Frame } from './pages/Frame';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation, useParams } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import 'katex/dist/katex.min.css'
import { Lambda } from '@aws-sdk/client-lambda';
import { useInjection } from 'inversify-react';
import { Credentials } from './services/Credentials';
import awsResources from './aws-resources.json'
import { FilesPage } from './pages/FilesPage';
import { API, PublicAPI } from './services/HTTP';
import { Domain } from './models/Domain';
import { DevicesPage } from './pages/DevicesPage';
import { PatientsPage } from './pages/PatientsPage';
import { CarerPage } from './pages/CarerPage';
import { HygieDataPage } from './pages/HygieDataPage';
import { DataPage } from './pages/DataPage';
import { Patient } from './models/Patient';
import { Button, Card, Modal } from 'react-bootstrap';
import { NewDomainForm } from './components/NewDomainForm';
import { CreateDomain } from './models/CreateDomain';
import { UserService } from './services/User';
import { DefaultDomainRedirect } from './pages/DefaultDomainRedirect';
import { CreateOnboardingPage } from './pages/CreateOnboardingPage';
import { PublicFrame } from './pages/PublicFrame';
import { AcknowledgeAlertPage } from './pages/AcknowledgeAlertPage';
import './i18n/i18n'
import { ArchivedPage } from './pages/ArchivedPage';
import { EventsPage } from './pages/EventsPage';
import { SettingsPage } from './pages/SettingsPage';
import { DomainRepository } from './services/DomainRepository';
import { UserManagementPage } from './pages/UserManagementPage';
import { BatteryPage } from './pages/BatteryPage';

export default function App() {
  const lastSelectedDomainId = "lastSelectedDomainId";

  const credentials = useInjection(Credentials);
  const domainRepository = useInjection(DomainRepository)
  const api = useInjection<API>("API");

  const scheme = window.location.href.split("://")[0];
  const host_and_port = window.location.href.split("://")[1].split("/")[0];
  const base_url = `${scheme}://${host_and_port}/`
  const public_path = window.location.href.split("://")[1].split("/")[1] == 'p';

  awsConfig.oauth.redirectSignIn = base_url;
  awsConfig.oauth.redirectSignOut = base_url;

  Amplify.configure(awsConfig);

  const [user, setUser] = useState<any | null>(null);
  const [cognitoGroups, setCognitoGroups] = useState<string[]>([]);
  const [authLoading, setAuthLoading] = useState(true);
  const [userAttributes, setUserAttributes] = useState<Map<string, string> | null>(null);
  const [showCreateDomainModal, setShowCreateDomainModal] = useState(false);
  const userServuce = useInjection<UserService>(UserService);
  const [isAdmin, setIsAdmin] = useState(false);

  const attachIoTPolicy = useCallback(async () => {
    const lambdaClient = new Lambda({
      region: awsConfig.aws_cognito_region,
      credentials: await credentials.getProvider()
    });

    try {
      return new TextDecoder().decode((await lambdaClient.invoke({
        FunctionName: awsResources.IoTAttachPrincipalLambdaName,
      })).Payload);
    } catch (e) {
      console.error("Could not attach iot principle: " + e);
    }
  }, [credentials]);

  useEffect(() => {
    // If we are not part of any cognito group, we cannot have access to any domain.
    if (!cognitoGroups || cognitoGroups.length == 0)
      return;

    if (user)
      userServuce.isAdmin().then(admin => setIsAdmin(admin));

  }, [cognitoGroups]);

  const loadAuthentication = useCallback(async (refreshSession: boolean = false) => {

    // Get the current authenticated user via AWS Amplify library.
    const user = await Auth.currentAuthenticatedUser()
      .catch((err) => {
        setAuthLoading(false);
        setUser(null);
        setUserAttributes(null);

        // If we have anything else than just a simple non-authenticated issue, we rethrow.
        if (err !== "The user is not authenticated") {
          throw err;
        }
      });

    // If we are not authenticated then we are done...
    if (!user)
      return;

    // If we request to we can update the JWT Access token via the refresh session command.
    if (refreshSession) {
      // refreshSession method is not async so we wrap it in a promise.
      await new Promise<void>(async (resolve, reject) => {

        // refresh the session with the current refresh token.
        user.refreshSession((await Auth.currentSession()).getRefreshToken(), async (error: any, session: any) => {

          // If that didn't work we need to logout, as we have no valid token anymore.
          if (error) {
            await Auth.signOut({ global: false });
            setUser(null);
            setUserAttributes(null);
            reject(error);
          }

          // Otherwise all's good we resolve.
          resolve();
        });
      });
    }

    // Load the cognito groups.
    const session = await Auth.currentSession();
    const cognitoGroups: string[] = session.getIdToken().payload["cognito:groups"];
    setCognitoGroups(cognitoGroups);

    // Load the user attributes.
    const attrL = await Auth.userAttributes(user);
    var attr: Map<string, string> = new Map();
    attrL.forEach(i => attr.set(i.getName(), i.getValue()));

    setUserAttributes(attr);
    setUser(user);

    // We need to make sure that the user is attached to the IoT policies.
    await attachIoTPolicy();

    return () => {
      // Nothing to cancel.
    }
  }, [attachIoTPolicy]);

  const createDomain = useCallback(async (domain: CreateDomain) => {
    // Creating the domain via API call, validation is done in the form
    // And will also be done by the API
    await api.post(`domains`, domain);

    // By creating the domain the user was added to a new cognito group
    // thus we need to reload the credentials to get a JWT where the
    // group association is updated.
    await loadAuthentication(true);

    // If all is fine we close the modal
    setShowCreateDomainModal(false);

  }, [api]);

  useEffect(() => {
    // Upon entering the application we load the authentication.
    loadAuthentication().catch(console.error);

  }, [loadAuthentication]);

  const logout = useCallback(async () => {
    await Auth.signOut({ global: false });
    loadAuthentication();
  }, []);
  
  const publicRoutes = <Route path='p' element={<PublicFrame />}>
    <Route index path='' element={<>Hello</>} />
    <Route index path='acknowledge' element={<AcknowledgeAlertPage />} />
    <Route index path='carer/:domain_id/patients/:patient_id' element={<CarerPage isPublic={true}/>} />  
  </Route>

  if (user != null)
    return <BrowserRouter>
      <Routes >
        <Route index element={<DefaultDomainRedirect
          onRefreshUserClicked={async () => { await loadAuthentication(true); }}
          onCreateDomain={() => setShowCreateDomainModal(true)}
          onLogoutClicked={() => logout()}
        ></DefaultDomainRedirect>}></Route>
        { publicRoutes }
        <Route path="d/:domain_id" element={<Frame
          userAttributes={userAttributes}
          onCreateDomain={() => setShowCreateDomainModal(true)}
          onLogout={() => {
            logout();
          }}
          onRefreshUserClicked={async () => { await loadAuthentication(true); }}
        />} errorElement={<ErrorPage />}>
          <Route index path='' element={<Navigate to="files" />} />
          <Route path='files/*' element={<FilesPage isProcessedPage={false} />} />
          <Route path='processed-files/*' element={<FilesPage isProcessedPage={true} />} />
          <Route path='battery' element={<BatteryPage />} />
          <Route path='patients' element={<PatientsPage />} />
          <Route path='patients/archived' element={<ArchivedPage />} />
          <Route path='patients/events' element={<EventsPage />} />
          <Route path='patients/:patient_id/details' element={<CarerPage isPublic={false}/>} />
          <Route path='data' element={<DataPage />} />
          <Route path='hygiedata' element={<HygieDataPage />} />
          <Route path='devices' element={<DevicesPage />} />
          <Route path='gateways/onboard' element={<CreateOnboardingPage />} />
          <Route path='settings/*' element={<SettingsPage />} />
          <Route path='usermanagement/*' element={<UserManagementPage/>}/>
          <Route path="*" element={<>No such page.</>} />
        </Route>
      </Routes>
      <Modal show={showCreateDomainModal} onHide={() => setShowCreateDomainModal(false)} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Create Domain</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewDomainForm createDomain={createDomain} />
        </Modal.Body>
      </Modal>
    </BrowserRouter>;

  if (public_path) {
    return <BrowserRouter>
      <Routes >
        {publicRoutes}
      </Routes>
    </BrowserRouter>;
  }

  return <Login authLoading={authLoading} onSuccessfulAuthentication={() => {
    loadAuthentication();
  }}></Login>

}

